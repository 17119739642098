import React, { useState, useRef, useEffect } from "react";
import "./welcome.scss";
import { Divider } from "primereact/divider";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { Toast } from "primereact/toast";
import { DashBoardMorning } from "./dashboard/DashBoardMorning";
import { DashBoardEvening } from "./dashboard/DashBoardEvening";
export const WelCome = () => {
  const toast = useRef(null);
  const [active, setActive] = useState(0);
  const [balance, setBalance] = useState(0);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const actmemid = localStorage.getItem("damanmemid");
  const actmememail = localStorage.getItem("damanemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getwelcomedata",
        {
          actmemid,
          actmememail,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setActive(resp.data.isActive);
        setBalance(resp.data.balance);
      } else {
      }
    };
    fetchData();
  }, []);
  const copyRef = (x) =>{
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Referal Linked Copied Successfully",
    });

  }
  return (
    <div className="welcome">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5 left">
          <div className="content">
            <div>WELCOME TO</div>
            <div className="brand">DAMAN BOT</div>
            <div className="tag">TRADING</div>
            <Divider />
            <div className="message">
              Even when he falls on straw, Schlemiel stumbles on a stone.
            </div>
            <Divider />
            <div className="actstatus">
              {active === 0 ? (
                <>
                  <Alert severity="error">
                    Not Activated!!! Please Acivate Your Account.
                  </Alert>
                  <div className="buttons">
                    <Button href="/user/inrdeposit" variant="outlined">
                      INR DEPOSIT
                    </Button>
                    <Button href="/user/cryptodeposit" variant="outlined">
                      CRYPTO DEPOSIT
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-7 right">
          <div className="grid">
            <div className="col-12 mywallet">
              <div>WALLET BALANCE</div>
              <div>{balance.toFixed(2)}</div>
            </div>
            <div className="col-12 mywallet">
              <div>Ref Link</div>
              <div className="link">
                <div>https://damanbottrading.com/signup/${actmemid}</div>
                <span className="pi pi-copy" onClick={()=>copyRef(`https://damanbottrading.com/signup/${actmemid}`)}></span>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-6 lg:col-6 first">
              <DashBoardMorning />
            </div>
            <div className="col-12 md:col-6 lg:col-6 second">
              <DashBoardEvening />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
