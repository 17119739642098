import React, { useState, useRef, useEffect } from "react";
import "./autobid.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { BidList } from "../../../components/user/BidList";
export const AutoBid = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [data, setData] = useState({});
  const [balance, setBalance] = useState(0);
  const [fdRate, setFdRate] = useState(0);
  const actmemid = localStorage.getItem("damanmemid");
  const actmememail = localStorage.getItem("damanemail");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const cities = [
    { name: "1 Month [30 Days]", days: "30" },
    { name: "3 Month [90 Days]", days: "90" },
    { name: "6 Month [180 Days]", days: "180" },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getautobid",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        formik.setFieldValue("balance",resp.data.balance)
        setBalance(resp.data.balance);
        setFdRate(resp.data.autobidRate);
        setData(resp.data.userautobid)
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    memberid: Yup.string().required(),
    balance: Yup.number("Balance Issue").required(""),
    amount: Yup.number("Invalid Bid, Please Check!!!").required(
      "Field can not be empty."
    ).min(200,"Minimum Bid is 200").lessThan(Yup.ref('balance'), 'Cannot Exceed Max Balance'),
  });
  const formik = useFormik({
    initialValues: {
      memberid: actmemid,
      balance:0,
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (selectedMonth === null) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please Select Duration",
        });
        return false;
      }
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/setautobid",
        {
          values,
          selectedMonth
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        setButtonDisabled(0);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const ClaimInterest = async () =>{
    const resp = await axios.post(
      url + "/user/calcfdinterest",
      {
        actmemid
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );

  }
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="autobid">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-4 left">
          <div className="content">
            <Card className="semiTrans">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div className="walletbal">
                      <div>Wallet Balance</div>
                      <div>{balance.toFixed(2)}</div>
                    </div>
                    <h3>AUTO BID@{fdRate}</h3>
                    <div className="component">
                      <Dropdown
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select Duration"
                      />
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                      {getFormErrorMessage("amount")}
                    </div>

                    <div className="component">
                      {isButtonDisabled === 0 ? (
                        <Button type="submit" label="Submit" size="large" />
                      ) : (
                        <>
                          {isButtonDisabled === 1 ? (
                            <Button
                              type="button"
                              disabled
                              label="Submitting"
                              size="large"
                            />
                          ) : (
                            <Button
                              type="button"
                              label="Submitted"
                              size="large"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-8 right">
          <Card title="AUTO BID DETAILS">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Pecentage</TableCell>
                    <TableCell align="center">Lock For</TableCell>
                    <TableCell align="center">Claim Days</TableCell>
                    <TableCell align="center">Current</TableCell>
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="center">Claim</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.rate}</TableCell>
                      <TableCell align="center">{row.for_days}</TableCell>
                      <TableCell align="center">{row.claim_days}</TableCell>
                      <TableCell align="center">{(row.till_date_amount).toFixed(2)}</TableCell>
                      <TableCell align="center">{(row.final_amount).toFixed(2)}</TableCell>
                      <TableCell align="center"><Button label="Claim" onClick={()=>ClaimInterest((row._id).toString())} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12">
          <BidList logedin="1" />
        </div>
      </div>
    </div>
  );
};
