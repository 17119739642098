import React, { useState, useRef } from "react";
import "./signup.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
export const Login = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const Navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.mixed("Invalid Email, Please Check!!!").required(
      "Field can not be empty."
    ),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password not matched"
    ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(true);
      const resp = await axios.post(
        url + "/userlogin",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        localStorage.setItem("damanmemid", resp.data.memberid);
        localStorage.setItem("damanemail", resp.data.email);
        Navigate("/user/");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <div className="signup">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-12 md:col-12 lg:col-4 left">
            <Card className="cust-bg">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/favicon.png" width="100" alt="" />
                    <h3>USER LOGIN</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <div className="component">
                      <Password
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        toggleMask
                      />
                      {getFormErrorMessage("password")}
                    </div>
                    <div className="component">
                      <Button type="submit" label="Submit" size="large" />
                    </div>
                    <div className="component">
                      <p>You have to verify email afterward.</p>
                    </div>
                    <div className="component">
                      <p>
                        Allready registered? <Link to="/login">Log In</Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-8 right">
            <div className="p-1 border-round-sm">
              <div className="cont">
                <div>WELCOME TO</div>
                <div className="homehead">DAMAN BOT</div>
                <div className="homemessage">TRADING</div>
                <Divider />
                <p>
                  Even when he falls on straw, Schlemiel stumbles on a stone.
                </p>
                <Divider />
                <div className="buttons">
                  <Button href="/login" variant="outlined" color="warning">
                    Log In
                  </Button>
                  <Button
                    href="/signup/191201"
                    variant="contained"
                    color="success"
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
