import React, { useState, useRef, useEffect } from "react";
import "./inrdeposit.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { BidList } from "../../../components/user/BidList";
export const InrDeposit = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [isButtonDisabled, setButtonDisabled] = useState(0);
  const [data, setData] = useState({});
  const actmemid = localStorage.getItem("damanmemid");
  const actmememail = localStorage.getItem("damanemail");
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getinrdeposits",
        {
          actmemid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setData(resp.data.inrdepositlist);
      }
    };
    fetchData();
  }, []);
  const validationSchema = Yup.object({
    memberid: Yup.string().required(),
    tranid: Yup.mixed("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
    amount: Yup.number("Invalid Transaction Id, Please Check!!!").required(
      "Field can not be empty."
    ),
  });
  const formik = useFormik({
    initialValues: {
      memberid: actmemid,
      tranid: "",
      amount: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setButtonDisabled(1);
      const resp = await axios.post(
        url + "/user/inrdepostreq",
        {
          values,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setButtonDisabled(2);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: resp.data.success,
        });
      } else {
        setButtonDisabled(0);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: resp.data.error,
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="inrdeposit">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-5 left">
          <div className="content">
            <Card className="semiTrans">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <img src="/images/qrcode.webp" width="150" alt="" />
                    <h3>INR DEPOSIT</h3>
                    <div className="component">
                      <InputText
                        type="text"
                        name="tranid"
                        placeholder="Transaction Id (UTR)"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.tranid}
                      />
                      {getFormErrorMessage("tranid")}
                    </div>
                    <div className="component">
                      <InputText
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                      />
                      {getFormErrorMessage("amount")}
                    </div>

                    <div className="component">
                      {isButtonDisabled === 0 ? (
                        <Button type="submit" label="Submit" size="large" />
                      ) : (
                        <>
                          {isButtonDisabled === 1 ? (
                            <Button
                              type="button"
                              disabled
                              label="Submitting"
                              size="large"
                            />
                          ) : (
                            <Button
                              type="button"
                              label="Submitted"
                              size="large"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
        <div className="col-12 md:col-12 lg:col-7 right">
          <Card title="INR DEPOSITS">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Status</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
