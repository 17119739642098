import { Card } from "primereact/card";
import "./userfooter.scss"
import {Link, useNavigate} from "react-router-dom";
export const UserFooter = () => {
  return (
    <>
    <div className="userfooter">
      <Card>
        <div className="cont">
          <Link to="/user/">
            <img src="/icons/home.webp" alt="" />
            <div>HOME</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/user/autobid/">
            <img src="/icons/singleid.webp" alt="" />
            <div>AUTO BID</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/user/inrdeposit">
            <img src="/icons/othertopup.webp" alt="" />
            <div>INR DEPOSIT</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/user/incomedetail">
            <img src="/icons/incomdetail.webp" alt="" />
            <div>INCOMES</div>
          </Link>
        </div>
        <div className="cont">
          <Link to="/user/withdraw">
            <img src="/icons/withdraw.webp" alt="" />
            <div>WITHDRAW</div>
          </Link>
        </div>
      </Card>
    </div>
    </>
  )
}
