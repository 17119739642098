import React, { useState, useRef, useEffect } from "react";
import "./admininrdeposit.scss";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import axios from "axios";
import { Toast } from "primereact/toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
export const AdmInrDeposit = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getinrdeposits", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setData(resp.data.inrdepositlist);
      }
    };
    fetchData();
  }, []);
  const approve = async(tranid) =>{
    const resp = await axios.post(url + "/admin/approveinrdeposit", {tranid}, {
        headers: {
          "x-api-key": apikey,
        },
      });
      if(resp.status===200){
        window.location.reload(true)
      }
  }
  const reject = () =>{

  }
  if (!data) return false;
  var rows = [];
  for (let i = 0; i < data.length; i++) {
    rows.push(data[i]);
  }
  return (
    <div className="admininrdeposit">
      <div className="grid">
        <div className="col-12 md:col-12 lg:col-12 right">
          <Card title="INR DEPOSITS">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">SL. No.</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Tran Id</TableCell>
                    <TableCell align="center">Amount</TableCell>
                    <TableCell align="center">Approve</TableCell>
                    <TableCell align="center">Reject</TableCell>
                    {/* <TableCell align="center">Claim</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={row.uuid}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {moment(row.tran_date)
                          .utcOffset(330)
                          .format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">{row.tranid}</TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center"><Button label="Approve" onClick={()=>approve(row.tranid)} /></TableCell>
                      <TableCell align="center"><Button severity="danger" label="Reject" onClick={()=>reject(row.tranid)} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </div>
  );
};
