import React, { useContext, useEffect, useState } from "react";
import "./userappbar.scss";
import { Menubar } from "primereact/menubar";
import { DarkModeContext } from "../../context/darkModeContext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
export const UserAppBar = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [hotbid, setHotBid] = useState(10001)
  useEffect(()=>{
    const fetchData = async () => {
      const resp = await axios.get(
        url + "/user/gethotbid",
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(resp.status===200){
        setHotBid(resp.data.hotgame.gameid)
      }
    }
    fetchData()
    
  },[])
  const Navigate = useNavigate();
  const { toggle, darkMode } = useContext(DarkModeContext);
  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      url: "/user/",
    },
    {
      label: "Inr Deposit",
      icon: "pi pi-indian-rupee",
      url: "/user/inrdeposit",
    },
    {
      label: "Crypto Diposit",
      icon: "pi pi-bitcoin",
      url: "/user/cryptodeposit",
    },
    {
      label: "Bidding",
      icon: "pi pi-star",
      items: [
        {
          label: "Regular Bid",
          icon: "pi pi-bolt",
          url:`/user/makebid/${hotbid}`
        },
        {
          label: "Auto Bid",
          icon: "pi pi-server",
          url: "/user/autobid/"
        },
      ],
    },
    {
      label: "Tran Details",
      icon: "pi pi-star",
      items: [
        {
          label: "Regular Bidding",
          icon: "pi pi-bolt",
        },
        {
          label: "Auto Bid",
          icon: "pi pi-server",
        },
      ],
    },
  ];
  const start = (
    <div className="appstart">
      <img alt="logo" src="/images/favicon.png" height="40" className="mr-2" />
      <span className="headlogo">DAMAN BOT</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-1">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        />
      )}
      <Button
        label="Log Out"
        icon="pi pi-power-off"
        size="small"
        severity="danger"
        className="ml-3"
        onClick={() => logout()}
      />
    </div>
  );
  const logout = () => {
    localStorage.removeItem('damanmemid');
    localStorage.removeItem('damanemail');
    Navigate("/");
  };
  return (
    <div>
      <div className="card userappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  );
};
