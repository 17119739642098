import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "./style.scss";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import { DarkModeContext } from "./context/darkModeContext";
import React, { useContext } from "react";
import { HomeAppBar } from "./components/home/HomeAppBar";
import { HomeFooter } from "./components/home/HomeFooter";
import { Home } from "./pages/home/Home";
import { Signup } from "./pages/home/Signup";
import { Login } from "./pages/home/Login";
import { DashBoard } from "./pages/user/dashboard/DashBoard";
import { UserAppBar } from "./components/user/UserAppBar";
import { UserFooter } from "./components/user/UserFooter";
import { InrDeposit } from "./pages/user/inrdeposit/InrDeposit";
import { AdminLogin } from "./pages/home/AdminLogin";
import { AdminDashBoard } from "./pages/admin/AdminDashBoard";
import { AdminAppBar } from "./components/admin/AdminAppBar";
import { AdminFooter } from "./components/admin/AdminFooter";
import { DailyBetting } from "./pages/admin/dailybetting/DailyBetting";
import { AdmInrDeposit } from "./pages/admin/AdmInrDeposit";
import { AutoBid } from "./pages/user/autobid/AutoBid";
import { BiddingList } from "./pages/admin/biddinglist/BiddingList";
import { MakeBid } from "./pages/user/makebid/MakeBid";
import { CryptoDeposit } from "./pages/user/cryptodeposit/CryptoDeposit";
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const HomeLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <HomeAppBar />
        <Outlet />
        <HomeFooter />
      </div>
    );
  };
  const UserLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <UserAppBar />
        <Outlet />
        <UserFooter />
      </div>
    );
  };
  const AdminLayout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <AdminAppBar />
        <Outlet />
        <AdminFooter />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/signup/:spid", element: <Signup /> },
        { path: "/login", element: <Login /> },
        { path: "/adminlogin", element: <AdminLogin /> },
        
      ]
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        { path: "/user/", element: <DashBoard /> },
        { path: "/user/inrdeposit", element: <InrDeposit /> },
        { path: "/user/autobid", element: <AutoBid /> },
        { path: "/user/makebid/:gameid", element: <MakeBid /> },
        { path: "/user/cryptodeposit", element: <CryptoDeposit /> },
        // { path: "/user/downline", element: <Downline /> },
        // { path: "/user/fetchtopup", element: <FetchTopUp /> },
        // { path: "/user/stacklist", element: <StackList /> },
        // { path: "/user/userincome/:incid", element: <UserIncome /> },

        // { path: "/user/withdraw", element: <Withdraw /> },
      
    ],
    },
    {
      path: "/admin",
      element: <AdminLayout />,
      children: [
        { path: "/admin/", element: <AdminDashBoard /> },
        { path: "/admin/dailybetting", element: <DailyBetting /> },
        { path: "/admin/inrdeposit", element: <AdmInrDeposit /> },      
        { path: "/admin/biddinglist", element: <BiddingList /> },      
        // { path: "/admin/usersprofile", element: <UsersProfile /> },      
        // { path: "/admin/liveaccountreq", element: <LiveAccountReq /> },      
        // { path: "/admin/editprofile/:email", element: <EditProfile /> },      
    ],
    }
  ])
  return (
    <RouterProvider router={router} />
  );
}

export default App;
